//
// Elements
// Settings - Tools - Generic - ELEMENTS - Objects - Components - Vendors - Utilities
//

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	display: flex;
	height: 100%;
	flex-direction: column;
	padding: 0;
	margin: 0;
	background-attachment: fixed;
	background: -webkit-linear-gradient(top,#083158 130px, #083158 130px,#F3F9FA 130px,#F3F9FA 400%);
	background-position: center;
	background-repeat: no-repeat;
	height: 100vh;
	
	@include admin-mode {
		background: white;
	}
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[os='Windows'] {
	::-webkit-scrollbar {
		width: 0.75rem;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 1rem;
	}

	::-webkit-scrollbar-track {
		border-radius: 1rem;
		box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0);
	}

	:hover::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.5);
	}
}

// stylelint-disable selector-max-specificity
// stylelint-disable selector-max-id
// stylelint-disable scale-unlimited/declaration-strict-value
#portal-notification {
	position: fixed;
	z-index: 9999;
}
// stylelint-enable selector-max-specificity
// stylelint-enable selector-max-id
// stylelint-enable scale-unlimited/declaration-strict-value
