@use 'sass:map';


.fwi-content {
    .h1 {
        font-size: 20 font-weight 700;
    }

    .title {
        @include font-size(1.5rem);
        font-weight: 700;
    }

    .description {
        font-weight: 300;
        color: black;
    }

    .label {
        @include font-size(1.0rem);
    }
}

legend {
    font-size: 0.9rem;
}

.cleartext {
    font-weight: 100;
}

table td.fit,
table th.fit {
    white-space: nowrap;
    width: 1%;
}


.folder-card {
    flex-direction: row;
    display:flex;
}

.fullscreen {
    display: block;       /* iframes are inline by default */
    background: white;
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units */
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9991;
}

.exit-fullscreen {
    z-index: 9999;
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0.7;
}